import React from 'react';

const MenuToggle = ({ onClick }) => (
	<button 
		className="navbar-toggler" 
		type="button" 
		data-toggle="collapse" 
		data-target="#navbarText" 
		aria-controls="navbarText" 
		aria-expanded="false" 
		aria-label="Toggle navigation">
		<span className="navbar-toggler-icon"></span>
    </button>
);

export default MenuToggle;
