import React from 'react';
import Header from './Header';
import Footer from './Footer';
import PropTypes from 'prop-types';
import '../style.scss';

const Layout = ({ children }) => (
  <>
    <Header />
    <div className="site-content">
	    <div className="container">
	    	{children}
	    </div>
    </div>
    <Footer />
    
  </>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
