import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
import MenuToggle from "./MenuToggle"
import Menu from "./Menu"

const HEADER_QUERY =  graphql`
  query SiteTitleQuery {
    site {
      siteMetadata {
        title
        description
      }
    }
  }
`

const Header = props => (
  <StaticQuery
    query={HEADER_QUERY}
    render={data => {
      return (
        <header id="masthead" className="site-header" style={{ "height" : "60px" }}>
          <nav className="navbar navbar-expand-lg navbar-light fixed-top border-bottom">
            <div className="container">
              <Link to="/" rel="home" className="navbar-brand">
                {data.site.siteMetadata.title}
              </Link>
              <MenuToggle />
              <Menu />
            </div> {/* .container */}
          </nav> {/* .navbar */}
        </header>
      )
    }}
  />
)

export default Header