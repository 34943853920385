/**
 * @type {{wordPressUrl: string}}
 */
const config = {
  SiteTitle: `Layerbag`,
  SiteUrl: `https://layerbag.com`,
  wordPressUrl: `https://lyrbg.com`,
  SiteDescription: `Design and Inspiration Magazine`,
  SiteAuthor: `Claude M`,
  trackingId: `G-C6JB3FEC49`,
  AdsenseAdClient: `ca-pub-6924258042019062`,
  AlgoliaAppId: `QWHM7TU13G`,
  AlgoliaApiKey: `9ce3ef5bf827971cf35434308e2b1f2a`,
  AlgoliaIndexName: `lyrbg_searchable_posts`,
  AnalyticsEmail: `claudemeri@gmail.com`,
  AnalyticsApiKey: `AIzaSyD1thDS3sDo6JzvsiSZderx9pFLPSyFX7k`,
  AnalyticsViewId: `29902301`,
  AnalyticsStartDate: `2010-01-01`,
}

module.exports = config
