import React from 'react';
import { Link, StaticQuery, graphql } from 'gatsby';
import { createLocalLink } from '../utils';

//import { Navbar, Nav, Button } from "react-bootstrap"

const MENU_QUERY = graphql`
  fragment MenuFields on WPGraphQL_MenuItem {
    id
    label
    url
    connectedObject {
      __typename
    }
  }

  query GET_MENU_ITEMS {
    wpgraphql {
      menuItems(where: { location: MAIN_MENU }) {
        nodes {
          ...MenuFields
          childItems {
            nodes {
              ...MenuFields
            }
          }
        }
      }
    }
  }
`;

const renderLink = menuItem =>
  menuItem.connectedObject.__typename === 'WPGraphQL_MenuItem' ? (
    <a href={menuItem.url} target="_blank" rel="noopener noreferrer">
      {menuItem.label}
    </a>
  ) : createLocalLink(menuItem.url) ? (
    <Link to={createLocalLink(menuItem.url)} className="nav-link">{menuItem.label}</Link>
  ) : (
    menuItem.label
  );

const renderMenuItem = menuItem => {
  if (menuItem.childItems && menuItem.childItems.nodes.length) {
    return renderSubMenu(menuItem);
  } else {
    return (
      <li className="nav-item" key={menuItem.id}>
        {renderLink(menuItem)}
      </li>
    );
  }
};

const renderSubMenu = menuItem => {
  return (
    <li className="has-subMenu menu-item" key={menuItem.id}>
      {renderLink(menuItem)}

      <ul className="menuItemGroup sub-menu">
        {menuItem.childItems.nodes.map(item => renderMenuItem(item))}
      </ul>
    </li>
  );
};

const Menu = ({ location }) => {

  return (
    <StaticQuery
      query={MENU_QUERY}
      render={data => {
        if (data.wpgraphql.menuItems) {
          return (
              
              <div className="collapse navbar-collapse" id="navbarText">
                <ul
                  id="menu-primary"
                  className="primary-menu navbar-nav mr-auto ml-0"
                >
                  {data.wpgraphql.menuItems.nodes.map(menuItem => {
                    if (menuItem.childItems.nodes.length) {
                      return renderSubMenu(menuItem);
                    } else {
                      return renderMenuItem(menuItem);
                    }
                  })}
                </ul>
                <ul className="navbar-nav ml-auto">
                  <li><a className="btn btn-info btn-sm btn-dark text-capitalize" href="https://madebysparks.lemonsqueezy.com">Shop</a></li>
                </ul>
              </div>
          );
        } else {
          return null;
        }
      }}
    />
  );
};

export default Menu;
