import React from 'react';
import { Link } from 'gatsby'

//import ScrollToTop from './ScrollToTop'

const Footer = () => (
  <footer className="site-footer border-top mt-5">
    <div className="container pt-5 pb-3">
      <div className="row">
        <div className="col-lg-6 mr-lg-auto">
              <div className="row mt-6 mt-lg-0">
                <div className="col-5 col-sm-4">
                  <h6>About</h6>
                  <ul className="list-unstyled ml-0">
                    <li><a className="text-muted" href="/archives/">Archives</a></li>
                    <li><a className="text-muted" href="/contact/">Contact</a></li>
                    <li><a className="text-muted" href="/advertise/">Advertise</a></li>
                    <li><a className="text-muted" href="/write-for-us/">Write for Us</a></li>
                  </ul>
                </div>
                <div className="col-12 d-sm-none"></div>
                <div className="col-7 col-sm-4 mt-4 mt-sm-0">
                  <h6>Follow Us</h6>
                  <ul className="list-unstyled ml-0">
                    <li><a className="text-muted" href="https://www.facebook.com/layerbag">Facebook</a></li>
                    <li><a className="text-muted" href="https://twitter.com/layerbag">Twitter</a></li>
                    <li><a className="text-muted" href="http://www.pinterest.com/Layerbag/">Pinterest</a></li>
                    <li><a className="text-muted" href="http://feeds.feedburner.com/layerbag">Rss</a></li>
                  </ul>
                </div>
                <div className="col-5 col-sm-4 mt-4 mt-sm-0">
                  <h6>Links</h6>
                  <ul className="list-unstyled ml-0">
                    <li><a className="text-muted" href="/category/free-psd/psd-mockups/">Mockups</a></li>
                    <li><a className="text-muted" href="/layerbag-scholarship-program/">Scholarship</a></li>
                    <li><a className="text-muted" href="/category/wordpress/themes/">Wordpress Themes</a></li>
                    <li><a className="text-muted" href="/hostgator-coupon-code-25/">Wordpress Hosting</a></li>
                  </ul>
                </div>
              </div>
          </div>
        <div className="col-lg-4">
              <h6>Affiliate Disclosure</h6>
              <p className="mt-3 mb-5 pl-lg-6 text-muted">We use affiliate programs for monetization on product reviews. By clicking on some links you will be redirected to the marketplace resulting in a compensation for us. We do our best to curating high quality products and content.</p>
          </div>
        <div className="col-12">
              <div className="small pt-4 border-top">
                © Copyright {new Date().getFullYear()} | Design by{` `}
                <Link to="/" rel="home" className="text-muted">LayerSupplyCo.</Link>            
                </div>
          </div>
      </div>
    </div>
  
  {/*<ScrollToTop scrollStepInPx="200" delayInMs="16.66" />*/}

  </footer>
);

export default Footer;
